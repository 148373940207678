var page = '';
if (document.querySelectorAll('section.survey').length) {
  page = 'survey';
} else if (document.querySelectorAll('section.admin').length) {
  page = 'admin';
}

/* Survey Slider */
var rangeSlider = function() {
  var slider = $('.range-slider'),
      range = $('.range-slider__range'),
      value = $('.range-slider__value'),
      hidden = $('input[type="hidden"]');

  value.each(function() {
    var value = $(this).next(hidden).val();

    if (value != '') {
      $(this).prev(range).val(value);
      $(this).html(Number(value).toFixed(0));
    } else {
      $(this).prev(range).val(0);
      $(this).html(Number(0).toFixed(0));
      $(this).next(hidden).val(0);
    }
  });

  slider.each(function() {
    range.on('input change', function() {
      if (this.value >= 0) {
        $(this).next(value).html(this.value);
        $(this).siblings(hidden).val(this.value);
      } else {
        $(this).next(value).html('-');
        $(this).siblings(hidden).val('');
      }
    });
  });
};

/* Init */
if (page == 'survey') {
  rangeSlider();
}
